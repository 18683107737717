<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listSaleAnalyseAPI,
  listSaleAnalyseSummaryAPI,
  shopSaleAPI,
  shopSaleSummaryAPI,
  listSaleAnalysisShopGoods,
  listSaleAnalysisShopGoodsSummary,
  categoryAPI,
  categorySummaryAPI,
  saleCashierAPI,
  saleCashierSummaryAPI,
  saleShopDateAPI,
  saleShopDateSummaryAPI,
  dateAPI,
  dateSummaryAPI,
  timeSlotAPI,
  timeSlotSummaryAPI,
  listSaleAnalysisCashierCategory,
  listSaleAnalysisCashierCategorySummary,
  shopCategoryAPI,
  shopCategorySummaryAPI,
} from "@/api/shop/analyse/posSale"; // 门店销售分析明细
export default {
  name: "SaleRank",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "门店销售分析",
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "goodsNos", label: "商品编号" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "barcodes", label: "条码" },
            ],
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              label: "商品",
              seniorSearch: true,
              // hide: () =>
              //   this.options?.tabColumnType == "shopsalesDetail" ||
              //   this.options?.tabColumnType == "salesGoods",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              // hide: () =>
              //   this.options?.tabColumnType == "shopsalesDetail" ||
              //   this.options?.tabColumnType == "salesGoods" ||
              //   this.options?.tabColumnType == "salesCategory" ||
              //   this.options?.tabColumnType == "salesShopCategory" ||
              //   this.options?.tabColumnType == "salesCategoryCashier",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listMachine",
            option: {
              filter: "machineIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "shopsalesDetail",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银机",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "machine" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              label: "收银员",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "shopsalesDetail" ||
                this.options?.tabColumnType == "salesDateCashier" ||
                this.options?.tabColumnType == "salesCategoryCashier",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              label: "门店",
              seniorSearch: true,
              // hide: () =>
              //   this.options?.tabColumnType == "shopsalesDetail" ||
              //   this.options?.tabColumnType == "salesDateShop",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "local",
            label: "业务类型",
            model: "",
            filter: "lineTypes",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "shopsalesDetail",
            option: {
              clearable: true,
              multiple: true,
              data: [
                {
                  label: "正常销售",
                  value: 0,
                },
                {
                  label: "临时赠品",
                  value: 1,
                },
                {
                  label: "固定组合商品",
                  value: 2,
                },
                {
                  label: "动态组合商品",
                  value: 3,
                },
                {
                  label: "买满赠送商品",
                  value: 4,
                },
                {
                  label: "组合优惠商品",
                  value: 5,
                },
                {
                  label: "优惠券销售",
                  value: 6,
                },
                {
                  label: "优惠券领取",
                  value: 7,
                },
                {
                  label: "优惠券赠送",
                  value: 8,
                },
                {
                  label: "辅料加值商品",
                  value: 9,
                },
                {
                  label: "积分兑换商品",
                  value: 21,
                },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          {
            hide: () => this.options?.tabColumnType == "shopsalesDetail",
            label: "小票号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNo",
          },
          {
            type: "local",
            label: "临时优惠",
            model: "",
            filter: "isTemporaryDiscount",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType != "salesShopGoods",
            option: {
              clearable: true,
              data: [
                {
                  label: "是",
                  value: true,
                },
                {
                  label: "否",
                  value: false,
                },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          {
            type: "local",
            label: "是否退货",
            model: "",
            filter: "isReturn",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "shopsalesDetail",
            option: {
              clearable: true,
              data: [
                {
                  label: "是",
                  value: true,
                },
                {
                  label: "否",
                  value: false,
                },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          {
            type: "local",
            label: "价格类型",
            model: "",
            filter: "agioTypes",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "shopsalesDetail",
            option: {
              clearable: true,
              multiple: true,
              data: [
                {
                  label: "正常价",
                  value: 0,
                },
                {
                  label: "会员价",
                  value: 1,
                },
                {
                  label: "特价促销",
                  value: 2,
                },
                {
                  label: "折扣优惠",
                  value: 3,
                },
                {
                  label: "数量特价",
                  value: 4,
                },
                {
                  label: "数量折扣",
                  value: 5,
                },
                {
                  label: "金额折扣",
                  value: 6,
                },
                {
                  label: "金额满减",
                  value: 7,
                },
                {
                  label: "买满赠送",
                  value: 8,
                },
                {
                  label: "固定组合",
                  value: 9,
                },
                {
                  label: "动态组合",
                  value: 10,
                },

                {
                  label: "组合优惠",
                  value: 11,
                },
                {
                  label: "折扣优惠券",
                  value: 12,
                },
                {
                  label: "抖音团购券",
                  value: 13,
                },
                {
                  label: "美团团购券",
                  value: 14,
                },
                {
                  label: "饿了么团购券",
                  value: 15,
                },
                {
                  label: "折扣优惠券",
                  value: 16,
                },
                {
                  label: "抖音次卡",
                  value: 17,
                },
                {
                  label: "美团折扣券",
                  value: 18,
                },
                {
                  label: "饿了么折扣券",
                  value: 19,
                },
                {
                  label: "积分兑换",
                  value: 20,
                },
                {
                  label: "预订优惠",
                  value: 21,
                },
                {
                  label: "额外赠送",
                  value: 22,
                },
                {
                  label: "充值赠品",
                  value: 49,
                },
                {
                  label: "充值免单",
                  value: 50,
                },
                {
                  label: "临时调价",
                  value: 51,
                },
                {
                  label: "临时折扣",
                  value: 52,
                },
                {
                  label: "去零头",
                  value: 53,
                },
                {
                  label: "拼团",
                  value: 101,
                },
                {
                  label: "秒杀",
                  value: 102,
                },
                {
                  label: "砍价",
                  value: 103,
                },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "shopsalesDetail",
        tabsColumns: [
          {
            title: "门店销售分析明细",
            type: "shopsalesDetail",
            getListApi: listSaleAnalyseAPI,
            getSummaryApi: listSaleAnalyseSummaryAPI,
            uuid: "9d33cfd6-c589-bad6-335f-b89a897a1083",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisDetailExport",
              exportName: "门店销售分析明细",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售时间",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "billNo",
                label: "小票号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitOldPrice",
                label: "原价",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitPrice",
                label: "售价",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioTypeName",
                label: "价格类型",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney1",
                label: "折扣金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioType2Name",
                label: "折上折类型",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "agioRate2",
                label: "折上折比率%",
                minWidth: 130,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney2",
                label: "折上折金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "roundMoney",
                label: "舍入金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "lineTypeName",
                label: "业务类型",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: [
              "unitQty",
              "agioMoney1",
              "saleMoney",
              "agioMoney2",
              "agioMoneySum",
              "roundMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "unitQtyReturn",
              "unitMoneyReturn",
              "unitOldMoney"
            ],
          },
          {
            title: "门店销售分析(按商品)",
            type: "salesGoods",
            getListApi: shopSaleAPI,
            getSummaryApi: shopSaleSummaryAPI,
            uuid: "a90548a0-0fec-873c-6777-be5c7de03e66",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisGoodsExport",
              exportName: "门店销售分析(按商品)",
            },
            //表格
            columns: [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              "customerOrdersQty",
              'unitOldMoney',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按门店+商品)",
            type: "salesShopGoods",
            getListApi: listSaleAnalysisShopGoods,
            getSummaryApi: listSaleAnalysisShopGoodsSummary,
            uuid: "a90548a0-0fec-873c-6777-be5c7de03e61",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "api/system/shop/report/sale/analysis/listSaleAnalysisShopGoodsExport",
              exportName: "门店销售分析(按门店+商品)",
            },
            //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 120,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 120,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              "customerOrdersQty",
              'unitOldMoney',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按日期)",
            type: "salesDate",
            getListApi: dateAPI,
            getSummaryApi: dateSummaryAPI,
            uuid: "9a88c811-12aa-ddb7-0f39-812abb816a37",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisDateExport",
              exportName: "门店销售分析(按日期)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              'unitOldMoney',
              'customerOrdersQty',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按日期+门店)",
            type: "salesDateShop",
            getListApi: saleShopDateAPI,
            getSummaryApi: saleShopDateSummaryAPI,
            uuid: "d8c3d510-2e20-401e-f1d1-ae62cade31f3",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisShopDateExport",
              exportName: "门店销售分析(按日期+门店)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              'unitOldMoney',
              'customerOrdersQty',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按日期+收银员)",
            type: "salesDateCashier",
            getListApi: saleCashierAPI,
            getSummaryApi: saleCashierSummaryAPI,
            uuid: "6b4fe947-4c5c-7c9d-3ddf-4bdafdc7b22f",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisCashierExport",
              exportName: "门店销售分析(按日期+收银员)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              'unitOldMoney',
              'customerOrdersQty',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按类别)",
            type: "salesCategory",
            getListApi: categoryAPI,
            getSummaryApi: categorySummaryAPI,
            uuid: "1342fbcf-70e6-4e10-7a11-f8fac6004edc",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisCategoryExport",
              exportName: "门店销售分析(按类别)",
            },
            //表格
            columns: [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              'unitOldMoney',
              'customerOrdersQty',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按门店+类别)",
            type: "salesShopCategory",
            getListApi: shopCategoryAPI,
            getSummaryApi: shopCategorySummaryAPI,
            uuid: "c111ea3f-3511-a354-b728-f23aab4b0332",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisShopCategoryExport",
              exportName: "门店销售分析(按门店+类别)",
            },
            //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 120,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 120,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              'unitOldMoney',
              'customerOrdersQty',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按收银员+类别)",
            type: "salesCategoryCashier",
            getListApi: listSaleAnalysisCashierCategory,
            getSummaryApi: listSaleAnalysisCashierCategorySummary,
            uuid: "73c2198d-47d1-9c5a-f8c1-7f175852643c",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisCashierCategoryExport",
              exportName: "门店销售分析(按收银员+类别)",
            },
            //表格
            columns: [
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              'unitOldMoney',
              'customerOrdersQty',
              'scrapQty',
              'scrapMoney'
            ],
          },
          {
            title: "门店销售分析(按时间段)",
            type: "salesTime",
            getListApi: timeSlotAPI,
            getSummaryApi: timeSlotSummaryAPI,
            uuid: "26911306-3cbd-2f93-99ba-9fed0282f9cf",
            // defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/sale/analysis/listSaleAnalysisTimeSlotExport",
              exportName: "门店销售分析(按时间段)",
            },
            //表格
            columns: [
              {
                prop: "timeSlot",
                label: "时间段",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitQtyGift",
                label: "赠送数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyGift",
                label: "赠送金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "优惠金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "unitQtyReturn",
                label: "退货数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoneyReturn",
                label: "退货金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "returnRate",
                label: "退货率(%)",
                minWidth: 100,
                align: "center",
              },
              {
                prop: "customerOrdersQty",
                label: "客单数",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapQty",
                label: "报废数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scrapMoney",
                label: "报废金额",
                minWidth: 120,
                align: "center",
              }
            ],
            summary: [
              "unitQty",
              "saleMoney",
              "unitQtyGift",
              "unitMoneyGift",
              "agioMoneySum",
              "unitQtyReturn",
              "unitMoneyReturn",
              'unitOldMoney',
              'customerOrdersQty',
              'scrapQty',
              'scrapMoney'
            ],
          },
        ],
      },
    };
  },
};
</script>
